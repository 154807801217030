import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { useLocation } from 'react-router-dom';
import { bool, func, number, object, string } from 'prop-types';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';

import { Form, PrimaryButton, PrimaryButtonInline, RangeSlider } from '../../../components';

import css from './PriceFilterForm.module.css';

const DEBOUNCE_WAIT_TIME = 500;

// Helper function to parse value for min handle
// Value needs to be between slider's minimum value and current maximum value
const parseMin = (min, currentMax) => value => {
  const parsedValue = Number.parseInt(value, 10);
  if (isNaN(parsedValue)) {
    return '';
  }
  return parsedValue < min ? min : parsedValue > currentMax ? currentMax : parsedValue;
};

// Helper function to parse value for max handle
// Value needs to be between slider's max value and current minimum value
const parseMax = (max, currentMin) => value => {
  const parsedValue = Number.parseInt(value, 10);
  if (isNaN(parsedValue)) {
    return '';
  }
  return parsedValue < currentMin ? currentMin : parsedValue > max ? max : parsedValue;
};

// PriceFilterForm component
const PriceFilterFormComponent = props => {
  const location = useLocation();
  const currentSearchParams = new URLSearchParams(location.search);
  const [radiusBarAlertMessage, setRadiusBarAlertMessage] = useState(null);
  const { liveEdit, onChange, onSubmit, onCancel, onClear, ...rest } = props;

  const boundsParam = currentSearchParams.get('bounds');
  const addressParam = currentSearchParams.get('address');
  const hasLocationInSearchParams = boundsParam && typeof addressParam === 'string';

  if (liveEdit && !onChange) {
    throw new Error('PriceFilterForm: if liveEdit is true you need to provide onChange function');
  }

  if (!liveEdit && !(onCancel && onClear && onSubmit)) {
    throw new Error(
      'PriceFilterForm: if liveEdit is false you need to provide onCancel, onClear, and onSubmit functions'
    );
  }

  const handleChange = debounce(
    formState => {
      const { minPrice, maxPrice, ...restValues } = formState.values;

      if (formState.dirty || minPrice >= 1) {
        // const { minPrice, maxPrice, ...restValues } = formState.values;
        // onChange({
        //   minPrice: minPrice === '' ? rest.min : minPrice,
        //   maxPrice: maxPrice === '' ? rest.max : maxPrice,
        //   ...restValues,
        // });

        if (currentSearchParams.get('bounds')) {
          const newSearchParams = {};
          const boundsLatLng = currentSearchParams.get('bounds')?.split(',');

          const neLat = boundsLatLng[0];
          const neLng = boundsLatLng[1];
          const swLat = boundsLatLng[2];
          const swLng = boundsLatLng[3];

          const centerLat = +(+neLat + +swLat) / 2;
          const centerLng = +(+neLng + +swLng) / 2;
          const degreePerKmLat = 0.008983;
          const degreePerKmLng = 0.008983 / Math.cos((centerLat * Math.PI) / 180);

          const deltaLat = minPrice * degreePerKmLat;
          const deltaLng = minPrice * degreePerKmLng;

          const latNE = centerLat + deltaLat;
          const lngNE = centerLng + deltaLng;
          const latSW = centerLat - deltaLat;
          const lngSW = centerLng - deltaLng;

          if (currentSearchParams.get('keywords')) {
            newSearchParams.keywords = currentSearchParams.get('keywords');
          }

          newSearchParams.address = currentSearchParams.get('address');
          newSearchParams.bounds = `${latNE},${lngNE},${latSW},${lngSW}`;
          onChange({
            bounds: newSearchParams.bounds,
            address: newSearchParams.address,
            minPrice: minPrice === '' ? rest.min : minPrice,
            maxPrice: maxPrice === '' ? rest.max : maxPrice,
            ...restValues,
          });
        }
      }
    },
    DEBOUNCE_WAIT_TIME,
    { leading: false, trailing: true }
  );

  const handleSubmit = values => {
    // const { minPrice, maxPrice, ...restValues } = values;
    // return onSubmit({
    //   minPrice: minPrice === '' ? rest.min : minPrice,
    //   maxPrice: maxPrice === '' ? rest.max : maxPrice,
    //   ...restValues,
    // });

    if (currentSearchParams.get('bounds')) {
      const newSearchParams = {};
      const { minPrice, maxPrice, ...restValues } = values;
      const boundsLatLng = currentSearchParams.get('bounds')?.split(',');

      const neLat = boundsLatLng[0];
      const neLng = boundsLatLng[1];
      const swLat = boundsLatLng[2];
      const swLng = boundsLatLng[3];

      const centerLat = +(+neLat + +swLat) / 2;
      const centerLng = +(+neLng + +swLng) / 2;
      const degreePerKmLat = 0.008983;
      const degreePerKmLng = 0.008983 / Math.cos((centerLat * Math.PI) / 180);

      const deltaLat = minPrice * degreePerKmLat;
      const deltaLng = minPrice * degreePerKmLng;

      const latNE = centerLat + deltaLat;
      const lngNE = centerLng + deltaLng;
      const latSW = centerLat - deltaLat;
      const lngSW = centerLng - deltaLng;

      newSearchParams.address = currentSearchParams.get('address');
      newSearchParams.bounds = `${latNE},${lngNE},${latSW},${lngSW}`;
      onSubmit({
        bounds: newSearchParams.bounds,
        address: newSearchParams.address,
        minPrice: minPrice === '' ? rest.min : minPrice,
        maxPrice: maxPrice === '' ? rest.max : maxPrice,
        ...restValues,
      });
    } else {
      setRadiusBarAlertMessage('Choose a location first');
    }
  };

  const formCallbacks = liveEdit
    ? { onSubmit: () => null }
    : { onSubmit: handleSubmit, onCancel, onClear };

  useEffect(() => {
    setRadiusBarAlertMessage(null);
  }, [boundsParam, addressParam]);

  return (
    <FinalForm
      {...rest}
      {...formCallbacks}
      render={formRenderProps => {
        const {
          form,
          handleSubmit,
          id,
          showAsPopup,
          onClear,
          onCancel,
          isOpen,
          isInSideBar,
          contentRef,
          style,
          intl,
          values,
          min,
          max,
          step,
          children,
        } = formRenderProps;
        const { minPrice: minPriceRaw, maxPrice: maxPriceRaw } = values;
        const minPrice = typeof minPriceRaw !== 'string' ? minPriceRaw : min;
        const maxPrice = typeof maxPriceRaw !== 'string' ? maxPriceRaw : max;

        const handleCancel = () => {
          // reset the final form to initialValues
          form.reset();
          onCancel();
        };

        // const clear = intl.formatMessage({ id: 'PriceFilterForm.clear' });
        // const cancel = intl.formatMessage({ id: 'PriceFilterForm.cancel' });
        // const submit = intl.formatMessage({ id: 'PriceFilterForm.submit' });
        const clear = intl.formatMessage({ id: 'RadiusFilterForm.clear' });
        const cancel = intl.formatMessage({ id: 'RadiusFilterForm.cancel' });
        const submit = intl.formatMessage({ id: 'RadiusFilterForm.submit' });

        const classes = classNames(css.root, {
          [css.popup]: showAsPopup,
          [css.isOpenAsPopup]: showAsPopup && isOpen,
          [css.plain]: !showAsPopup,
          [css.isOpen]: !showAsPopup && isOpen,
        });

        useEffect(() => {
          const radiusValue = +currentSearchParams.get('radius');

          if (radiusValue && radiusValue >= 1 && radiusValue <= 100) {
            form.change('minPrice', +currentSearchParams.get('radius'));
          } else {
            form.change('minPrice', 1);
          }
        }, [location.search]);

        return (
          <Form
            tabIndex="0"
            className={classes}
            onSubmit={handleSubmit}
            contentRef={contentRef}
            style={{ minWidth: '300px', ...style }}
          >
            <div className={css.contentWrapper}>
              {showAsPopup ? (
                <span className={css.label}>
                  <FormattedMessage id="RadiusFilterForm.label" />
                </span>
              ) : null}

              <div className={css.inputsWrapper}>
                <Field
                  className={classNames(css.minPrice, { [css.priceInSidebar]: isInSideBar })}
                  id={`${id}.minPrice`}
                  name="minPrice"
                  component="input"
                  type="number"
                  placeholder={min}
                  min={min}
                  max={max}
                  step={step}
                  parse={parseMin(min, maxPrice)}
                  disabled={!hasLocationInSearchParams}
                />

                {/* <span className={css.priceSeparator}>-</span> */}

                {/* <Field
                  className={classNames(css.maxPrice, { [css.priceInSidebar]: isInSideBar })}
                  id={`${id}.maxPrice`}
                  name="maxPrice"
                  component="input"
                  type="number"
                  placeholder={max}
                  min={min}
                  max={max}
                  step={step}
                  parse={parseMax(max, minPrice)}
                  disabled
                /> */}
              </div>
            </div>

            {/* <div className={css.sliderWrapper}> */}
            <div>
              <RangeSlider
                min={min}
                max={max}
                step={hasLocationInSearchParams ? step : 0}
                handles={[minPrice, maxPrice]}
                onChange={handles => {
                  form.change('minPrice', handles[0]);
                  // form.change('maxPrice', handles[1]);
                }}
                setRadiusBarAlertMessage={setRadiusBarAlertMessage}
                hasLocationInSearchParams={hasLocationInSearchParams}
              />
              <div className={css.priceRangeTextDiv}>
                <div className={css.minPriceDiv}>
                  {/* <label>Min:</label> */}
                  <Field
                    id={`${id}.minPrice`}
                    name="minPrice"
                    component="input"
                    type="number"
                    placeholder={min}
                    min={min}
                    max={max}
                    step={step}
                    parse={parseMin(min, maxPrice)}
                    disabled={!hasLocationInSearchParams}
                  />
                </div>
                <div className={css.maxPriceDiv}>
                  {/* <label>Max:</label> */}
                  <Field
                    id={`${id}.maxPrice`}
                    name="maxPrice"
                    component="input"
                    type="number"
                    placeholder={max}
                    min={min}
                    max={max}
                    step={step}
                    parse={parseMax(max, minPrice)}
                    disabled
                  />
                </div>
              </div>
              {radiusBarAlertMessage && (
                <div className={css.radiusAlert}>{radiusBarAlertMessage}</div>
              )}
            </div>

            {liveEdit ? (
              <FormSpy onChange={handleChange} subscription={{ values: true, dirty: true }} />
            ) : (
              <div className={css.buttonsWrapper}>
                {/* <button className={css.clearButton} type="button" onClick={onClear}>
                  {clear}
                </button>
                <button className={css.cancelButton} type="button" onClick={handleCancel}>
                  {cancel}
                </button>
                <button className={css.submitButton} type="submit" onClick={handleSubmit}>
                  {submit}
                </button> */}

                <PrimaryButton
                  type="submit"
                  onClick={handleSubmit}
                  className={css.singleSubmitButton}
                  // disabled={!hasLocationInSearchParams}
                >
                  {submit}
                </PrimaryButton>
              </div>
            )}
            {children}
          </Form>
        );
      }}
    />
  );
};

PriceFilterFormComponent.defaultProps = {
  liveEdit: false,
  showAsPopup: false,
  isOpen: false,
  contentRef: null,
  style: null,
  min: 0,
  step: 1,
  onCancel: null,
  onChange: null,
  onClear: null,
  onSubmit: null,
};

PriceFilterFormComponent.propTypes = {
  id: string.isRequired,
  liveEdit: bool,
  showAsPopup: bool,
  onCancel: func,
  onChange: func,
  onClear: func,
  onSubmit: func,
  isOpen: bool,
  contentRef: func,
  style: object,
  min: number.isRequired,
  max: number.isRequired,
  step: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

const PriceFilterForm = injectIntl(PriceFilterFormComponent);

export default PriceFilterForm;
